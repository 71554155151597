@import url(https://fonts.googleapis.com/css?family=Raleway:800|Merriweather+Sans|Share+Tech+Mono|Ruda);
@import url(https://fonts.googleapis.com/css?family=Raleway:800|Merriweather+Sans|Share+Tech+Mono|Ruda);
@import url(https://fonts.googleapis.com/css?family=Raleway:800|Merriweather+Sans|Share+Tech+Mono|Ruda);
@import url(https://fonts.googleapis.com/css?family=Raleway:800|Merriweather+Sans|Share+Tech+Mono|Ruda);
@import url(https://fonts.googleapis.com/css?family=Raleway:800|Merriweather+Sans|Share+Tech+Mono|Ruda);
@import url(https://fonts.googleapis.com/css?family=Raleway:800|Merriweather+Sans|Share+Tech+Mono|Ruda);
@import url(https://fonts.googleapis.com/css?family=Raleway:800|Merriweather+Sans|Share+Tech+Mono|Ruda);
@import url(https://fonts.googleapis.com/css?family=Raleway:800|Merriweather+Sans|Share+Tech+Mono|Ruda);
* {
  box-sizing: border-box; }

html,
body {
  height: 100%;
  width: 100%;
  background: #001c38; }

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale; }

#root {
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%; }

#mask {
  background-image: -webkit-gradient(linear, left top, right bottom, from(#001c38), to(black)), -webkit-gradient(linear, left top, left bottom, from(transparent), color-stop(70%, black));
  background-image: -webkit-linear-gradient(left top, #001c38, black), -webkit-linear-gradient(transparent, black 70%);
  background-image: linear-gradient(to right bottom, #001c38, black), linear-gradient(transparent, black 70%);
  height: 100%;
  width: 100%;
  background-size: cover;
  background-repeat: no-repeat;
  left: 0;
  top: 0;
  position: fixed;
  will-change: transform;
  -webkit-transition: opacity 0.25s;
  transition: opacity 0.25s;
  z-index: 100; }

div[tabindex][role="group"] {
  flex: 1 1;
  display: flex;
  height: 100%;
  overflow-y: auto; }

a {
  color: inherit;
  text-decoration: none; }

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
textarea:-webkit-autofill,
textarea:-webkit-autofill:hover,
textarea:-webkit-autofill:focus,
select:-webkit-autofill,
select:-webkit-autofill:hover,
select:-webkit-autofill:focus {
  border: 0;
  border-radius: 2px;
  -webkit-text-fill-color: #fff;
  -webkit-box-shadow: 0 0 0px 1000px #2a3747 inset;
  -webkit-transition: background-color 5000s ease-in-out 0s;
  transition: background-color 5000s ease-in-out 0s;
  caret-color: #fff; }

.Content {
  flex: 1 1;
  display: flex;
  flex-direction: column; }

.Content__header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%; }

.Content__header-title {
  font-size: 36px;
  line-height: 44px;
  letter-spacing: -0.005em;
  font-weight: bold;
  margin: 24px 0;
  border-bottom: 2px solid #fa4141; }

.Content__header-action {
  display: flex;
  align-items: center;
  min-height: 30px;
  font-size: 14px;
  font-weight: bold;
  line-height: 16px;
  letter-spacing: 0.16em;
  text-transform: uppercase;
  text-decoration: none;
  cursor: pointer;
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none; }

.Effect__linkFadeOpacity {
  -webkit-transition-property: opacity;
  transition-property: opacity;
  -webkit-transition-duration: 0.2s;
          transition-duration: 0.2s;
  -webkit-transition-timing-function: linear;
          transition-timing-function: linear;
  opacity: 0.6; }

.Effect__linkFadeOpacity:hover,
.Effect__linkFadeOpacity:focus {
  opacity: 1; }

.Content__body {
  flex: 1 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  flex-wrap: wrap;
  width: 100%;
  padding: 0 8px; }

#View {
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 0 32px;
  margin: 0 auto;
  max-width: 1480px;
  z-index: 200;
  font-family: "Ruda", sans-serif;
  font-size: 14px;
  color: #fff; }

.View__notFound--image {
  width: 450px;
  margin: 15px 0;
  box-shadow: rgba(0, 0, 0, 0.2) 0 0 8px;
  border-radius: 10px; }

.GridItem {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 25%;
  min-width: 150px;
  max-width: 300px;
  padding: 0 8px 2.5em;
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none; }

.GridItem__imageFrame {
  position: relative;
  width: 100%;
  background: #fa4141;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
  -webkit-box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
  cursor: pointer; }

.GridItem__imageFrame:after {
  content: "";
  display: block;
  padding-bottom: 100%; }

.GridItem__imageFrame-placeholder {
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  top: 0;
  width: 100%;
  height: 100%;
  font-size: 75px; }

.GridItem__link {
  display: flex;
  flex-direction: column;
  width: 100%; }

.GridItem__imageFrame-content {
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  width: 100%; }

.GridItem__info {
  display: flex;
  flex-direction: column;
  align-items: center;
  cursor: pointer; }

.GridItem__name {
  margin: 12px 0 4px;
  font-weight: bold;
  text-overflow: ellipsis;
  line-height: 20px;
  letter-spacing: 0.015em; }

.GridItem__description {
  opacity: 0.6;
  text-overflow: ellipsis;
  overflow: hidden; }

.Effect__filterDarken {
  -webkit-filter: brightness(1);
          filter: brightness(1); }

.Effect__filterDarken:hover > .GridItem__imageFrame,
.Effect__filterDarken:focus > .GridItem__imageFrame {
  transition-property: filter, -webkit-filter;
  -webkit-transition-duration: 0.2s;
          transition-duration: 0.2s;
  -webkit-transition-timing-function: cubic-bezier(0.3, 0, 0, 1);
          transition-timing-function: cubic-bezier(0.3, 0, 0, 1);
  -webkit-filter: brightness(0.3);
          filter: brightness(0.3); }

.Grid {
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  margin-top: 15px; }

.Form__field {
  display: flex;
  flex-direction: column;
  margin-top: 0.7em;
  margin-bottom: 1em; }

.Form__field-label {
  font-weight: bold; }

.Form__field-label--required {
  color: #fa4141; }

.Form__field-input {
  height: 48px;
  width: 100%;
  margin-top: 0.7em;
  padding: 0 0.75em;
  background: #2a3747;
  color: #fff;
  font-size: 16px;
  font-weight: bold;
  letter-spacing: 0.1em;
  outline: none;
  border: 0;
  border-radius: 2px;
  font-family: "Ruda", sans-serif; }

.Form__field-error {
  margin-left: 15px;
  color: #ff5454;
  font-weight: bold;
  letter-spacing: 0.05em; }

.Form__button {
  display: flex;
  justify-content: center;
  align-items: center;
  align-self: center;
  min-width: 160px;
  max-width: 320px;
  margin: 0.7em 16px 1em;
  padding: 10px 0;
  font-family: inherit;
  font-size: 13px;
  font-weight: bold;
  line-height: 1;
  color: #fff;
  text-transform: uppercase;
  letter-spacing: 0.16em;
  border-radius: 500px;
  border: 0;
  outline: none;
  cursor: pointer;
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none; }

.Effect__bgFade {
  background: #fa4141;
  transition-property: background-color, border-color, color, box-shadow, filter, -webkit-filter;
  -webkit-transition-duration: 0.3s;
          transition-duration: 0.3s;
  letter-spacing: 2px;
  white-space: normal; }

.Effect__bgFade:hover {
  background-color: #ff5c5c; }

.Form__button--disabled {
  background: #2a3747;
  opacity: 0.5; }

.Form {
  display: flex;
  align-self: center;
  flex-direction: column;
  width: 100%;
  max-width: 600px; }

.Form__message--error {
  display: flex;
  align-items: center;
  justify-content: center;
  padding-bottom: 0.75em;
  color: #39f7fa;
  font-size: 16px;
  font-weight: bold;
  letter-spacing: 1px; }

.Button {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0 10px;
  margin-top: 16px;
  margin-bottom: 0;
  background: rgba(0, 28, 56, 0.7);
  font-size: 14px;
  font-weight: bold;
  text-transform: uppercase;
  letter-spacing: 0.16em;
  line-height: 1.6;
  border: 0;
  border-radius: 500px;
  box-shadow: inset 0 0 0 2px #b3b3b3;
  will-change: transform;
  cursor: pointer;
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none; }

.Button--small {
  min-width: 240px;
  padding: 7px 22px; }

.Button--xsmall {
  min-width: 140px;
  padding: 7px 22px; }

.Effect__grow {
  -webkit-transition: none 33ms cubic-bezier(0.3, 0, 0.7, 1);
  transition: none 33ms cubic-bezier(0.3, 0, 0.7, 1);
  -webkit-transition-property: all;
  transition-property: all; }

.Effect__grow:hover,
.Effect__grow:focus {
  box-shadow: inset 0 0 0 2px #fff, 0 0 0 1px transparent;
  -webkit-transform: scale(1.06);
          transform: scale(1.06); }

.FieldTable {
  display: flex;
  align-self: center;
  flex-direction: column;
  width: 100%;
  max-width: 600px; }

.FieldTable__field {
  display: flex;
  flex-direction: column;
  margin-top: 0.7em;
  margin-bottom: 1em; }

.FieldTable__label {
  font-weight: bold; }

.FieldTable__value {
  display: flex;
  align-items: center;
  height: 48px;
  width: 100%;
  margin-top: 0.7em;
  padding: 0 0.75em;
  background: #011120;
  color: #fff;
  font-size: 16px;
  letter-spacing: 0.1em;
  outline: none;
  border: 0;
  border-radius: 2px;
  font-family: "Ruda", sans-serif; }

.UserProfile__avatar {
  height: 150px;
  width: 150px;
  margin: 0 0 24px 0;
  border-radius: 50%; }

#NavBar {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  height: 100%;
  width: 230px;
  margin: 0;
  padding: 24px 0 0 0;
  background: #011120;
  font-family: "Ruda", sans-serif;
  font-size: 14px;
  font-weight: bold;
  z-index: 9999;
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none;
  cursor: default; }

.NavBar__header {
  display: flex;
  justify-content: center;
  height: 60px;
  width: 100%;
  font-family: "Share Tech Mono", monospace;
  font-size: 24px;
  color: #fff; }

.NavBar__menu {
  display: flex;
  flex-direction: column;
  width: 100%;
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none; }

.NavBar__menuItem {
  width: 100%;
  line-height: 20px;
  letter-spacing: 0.015em; }

.NavBar__menuLink-wrapper {
  position: relative;
  display: flex;
  color: inherit;
  text-decoration: none; }

.NavBar__menuLink {
  position: relative;
  display: flex;
  align-items: center;
  height: 40px;
  width: 100%;
  padding: 0 24px; }

.NavBar__menuLink--active {
  color: #fff; }

.NavBar__menuLink--active:after {
  content: "";
  display: block;
  position: absolute;
  top: 5px;
  bottom: 5px;
  width: 4px;
  background: #fa4141; }

.NavBar__menuLink-icon {
  display: flex;
  align-items: center;
  height: 24px;
  width: 24px; }

.NavBar__menuLink-label {
  margin-left: 16px; }

.Effect__linkFade:not(.NavBar__menuLink--active) {
  -webkit-transition-property: color;
  transition-property: color;
  -webkit-transition-duration: 0.2s;
          transition-duration: 0.2s;
  -webkit-transition-timing-function: linear;
          transition-timing-function: linear;
  color: #b3b3b3; }

.Effect__linkFade:hover,
.Effect__linkFade:focus {
  color: #fff; }

.NavBar__list {
  flex: 1 1;
  color: #fff; }

.NavBar__footer {
  display: flex;
  flex-direction: column;
  width: 100%;
  color: #fff; }

.NavBar__userInfo {
  display: flex;
  margin: 8px 24px 24px 24px;
  padding-top: 10px;
  border-top: 1px solid #546172; }

.NavBar__userInfo-link {
  display: flex;
  align-items: center;
  color: inherit;
  text-decoration: none;
  cursor: pointer; }

.NavBar__userInfo-link--active {
  color: #fa4141; }

.NavBar__userInfo-avatar {
  display: flex;
  align-items: center;
  height: 28px;
  width: 28px; }

.NavBar__userInfo-name {
  display: flex;
  align-items: center;
  margin-left: 10px; }

.MenuItem {
  display: flex;
  flex-direction: column; }

.MenuItem__image {
  width: 450px;
  box-shadow: rgba(0, 0, 0, 0.2) 0 0 8px;
  border-radius: 10px; }

.MenuItem__price {
  font-size: 32px;
  font-weight: 900;
  direction: ltr;
  margin: 20px 0; }

.MenuItem__details {
  padding: 30px 0;
  margin-bottom: 15px;
  font-size: 16px;
  border-top: 1px solid #2a3747;
  border-bottom: 1px solid #2a3747; }

.MenuItem__actions {
  display: flex;
  justify-content: space-around;
  width: 100%; }

.App {
  display: flex;
  width: 100%;
  height: 100%; }

.App__loader {
  display: flex;
  width: 100%;
  height: 100%;
  justify-content: center;
  align-items: center;
  color: #fff;
  font-size: 90px;
  z-index: 9999; }

