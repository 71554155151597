@import "../theme/theme";

.GridItem {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 25%;
  min-width: 150px;
  max-width: 300px;
  padding: 0 8px 2.5em;

  user-select: none;
}

.GridItem__imageFrame {
  position: relative;
  width: 100%;
  background: $highlight;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
  -webkit-box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);

  cursor: pointer;
}

.GridItem__imageFrame:after {
  content: "";
  display: block;
  padding-bottom: 100%;
}

.GridItem__imageFrame-placeholder {
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  top: 0;
  width: 100%;
  height: 100%;
  font-size: 75px;
}

.GridItem__link {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.GridItem__imageFrame-content {
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  width: 100%;
}

.GridItem__info {
  display: flex;
  flex-direction: column;
  align-items: center;

  cursor: pointer;
}

.GridItem__name {
  margin: 12px 0 4px;
  font-weight: bold;
  text-overflow: ellipsis;
  line-height: 20px;
  letter-spacing: 0.015em;
}

.GridItem__description {
  opacity: 0.6;
  text-overflow: ellipsis;
  overflow: hidden;
}

.Effect__filterDarken {
  filter: brightness(1);
}

.Effect__filterDarken:hover > .GridItem__imageFrame,
.Effect__filterDarken:focus > .GridItem__imageFrame {
  transition-property: filter, -webkit-filter;
  transition-duration: 0.2s;
  transition-timing-function: cubic-bezier(0.3, 0, 0, 1);
  filter: brightness(0.3);
}
