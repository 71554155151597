$primary: #ffffff;
$primary-light: #ffffff;
$primary-dark: #cccccc;

$secondary: #2a3747;
$secondary-light: #546172;
$secondary-dark: #011120;

$on-primary: #121212;
$on-primary-light: #a1a1a1;
$on-secondary: #fff;

$highlight: #fa4141;
$super-highlight: #ff5454;
$highlight-2: #7755cc;
$highlight-3: #16abae;
$super-highlight-3: #39f7fa;
