@import "../../theme/theme";

.FieldTable {
  display: flex;
  align-self: center;
  flex-direction: column;
  width: 100%;
  max-width: 600px;
}

.FieldTable__field {
  display: flex;
  flex-direction: column;
  margin-top: 0.7em;
  margin-bottom: 1em;
}

.FieldTable__label {
  font-weight: bold;
}

.FieldTable__value {
  display: flex;
  align-items: center;
  height: 48px;
  width: 100%;

  margin-top: 0.7em;
  padding: 0 0.75em;

  background: $secondary-dark;

  color: $on-secondary;
  font-size: 16px;
  letter-spacing: 0.1em;

  outline: none;
  border: 0;
  border-radius: 2px;

  font-family: $core-font;
}
