@import "../../theme/theme";

.MenuItem {
  display: flex;
  flex-direction: column;
}

.MenuItem__image {
  width: 450px;
  box-shadow: rgba(0, 0, 0, 0.2) 0 0 8px;
  border-radius: 10px;
}

.MenuItem__price {
  font-size: 32px;
  font-weight: 900;
  direction: ltr;
  margin: 20px 0;
}

.MenuItem__details {
  padding: 30px 0;
  margin-bottom: 15px;

  font-size: 16px;

  border-top: 1px solid $secondary;
  border-bottom: 1px solid $secondary;
}

.MenuItem__actions {
  display: flex;
  justify-content: space-around;

  width: 100%;
}
