@import "../../theme/colors";

.Form {
  display: flex;
  align-self: center;
  flex-direction: column;
  width: 100%;
  max-width: 600px;
}

.Form__message--error {
  display: flex;
  align-items: center;
  justify-content: center;
  padding-bottom: 0.75em;
  color: $super-highlight-3;
  font-size: 16px;
  font-weight: bold;
  letter-spacing: 1px;
}
