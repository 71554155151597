.Button {
  display: flex;
  align-items: center;
  justify-content: center;

  padding: 0 10px;
  margin-top: 16px;
  margin-bottom: 0;

  background: rgba(0, 28, 56, 0.7);

  font-size: 14px;
  font-weight: bold;
  text-transform: uppercase;
  letter-spacing: 0.16em;
  line-height: 1.6;

  border: 0;
  border-radius: 500px;

  box-shadow: inset 0 0 0 2px #b3b3b3;

  will-change: transform;

  cursor: pointer;
  user-select: none;
}

.Button--small {
  min-width: 240px;
  padding: 7px 22px;
}

.Button--xsmall {
  min-width: 140px;
  padding: 7px 22px;
}

.Effect__grow {
  transition: none 33ms cubic-bezier(0.3, 0, 0.7, 1);
  transition-property: all;
}

.Effect__grow:hover,
.Effect__grow:focus {
  box-shadow: inset 0 0 0 2px #fff, 0 0 0 1px transparent;
  transform: scale(1.06);
}
